import "./styles/loginModal.css";

interface ModalProps {
  isOpen: boolean;
}

const LoginModal = ({ isOpen }: ModalProps) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <pentagon-widget clientid="rf9fac626c3dRFLZ238z" />
        <div className="desc">
          To access the faucet, log in with your Pentagon account.
          <br /> Once logged in, you can claim a faucet token in your connected
          wallet.
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
