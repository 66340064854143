declare global {
  interface Window {
    grecaptcha: any;
  }
}

export default class ReCaptcha {
  private static instance: ReCaptcha;
  siteKey: string;
  v2siteKey?: string;
  action: string;
  widgetID: string | undefined;
  setWidgetID: any;

  constructor(
    SITE_KEY: string,
    ACTION: string,
    V2_SITE_KEY: string,
    setWidgetID: any,
    widgetID: string | undefined
  ) {
    this.loadReCaptcha(SITE_KEY);

    this.siteKey = SITE_KEY;
    this.v2siteKey = V2_SITE_KEY;
    this.action = ACTION;
    this.widgetID = widgetID;
    this.setWidgetID = setWidgetID;
  }

  public static getInstance(
    SITE_KEY: string,
    ACTION: string,
    V2_SITE_KEY: string,
    setWidgetID: any,
    widgetID: string | undefined
  ): ReCaptcha {
    if (!ReCaptcha.instance) {
      ReCaptcha.instance = new ReCaptcha(
        SITE_KEY,
        ACTION,
        V2_SITE_KEY,
        setWidgetID,
        widgetID
      );
    }
    return ReCaptcha.instance;
  }

  async getToken(isV2 = false): Promise<{ token?: string; v2Token?: string }> {
    let token = "",
      v2Token = "";

    v2Token = await window.grecaptcha.getResponse(this.widgetID);

    return { token, v2Token };
  }

  resetV2Captcha = () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const v2CaptchaContainer = <HTMLElement>(
      document.getElementsByClassName("v2-recaptcha")[0]
    );
    if (v2CaptchaContainer) {
      if (this.widgetID) {
        window.grecaptcha.reset(this.widgetID);
      }
      v2CaptchaContainer.style.display = "none";
    }
  };

  loadV2Captcha = (v2siteKey: string) => {
    const v2CaptchaContainer =
      document.getElementsByClassName("v2-recaptcha")[0];

    if (this.widgetID || this.widgetID === "0") {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const v2CaptchaContainer = <HTMLElement>(
        document.getElementsByClassName("v2-recaptcha")[0]
      );
      if (v2CaptchaContainer) {
        v2CaptchaContainer.style.display = "block";
      }
    } else {
      const widgetID = window.grecaptcha.render(v2CaptchaContainer, {
        sitekey: v2siteKey,
        theme: "dark",
      });
      this.setWidgetID(widgetID);
    }

    return true;
  };

  loadReCaptcha = (siteKey: string): boolean => {
    const script = document.createElement("script");
    script.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?compat=recaptcha`;

    document.body.appendChild(script);
    return true;
  };
}
